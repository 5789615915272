import classes from './styles.module.scss'
import { LanguageSwitcher } from '../LanguageSwitcher'
import SocialButtons from '../SocialButtons'
import { useCountryConfig } from 'app/context/country'

function MenuFooter() {
  const { socialLinksFeature } = useCountryConfig()

  return (
    <>
      <div className={classes.root} data-test='menu-footer-links'>
        {socialLinksFeature.enabled && <SocialButtons />}
        <LanguageSwitcher />
      </div>
    </>
  )
}

export default MenuFooter
