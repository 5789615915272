import { useEffect } from 'react'

function SmoothscrollLoader(): null {
  useEffect(() => {
    setTimeout(() => {
      // load smoothscroll polyfill only for desktop
      if (typeof window !== 'undefined') {
        const smoothscroll = require('smoothscroll-polyfill')
        smoothscroll.polyfill()
      }
    }, 1000)
  }, [])

  return null
}

export default SmoothscrollLoader
