import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import ROUTES from 'temp/routes.json'

export default function useIsAuthPage() {
  const [isAuth, setIsAuth] = useState(false)
  const pathname = usePathname()

  useEffect(() => {
    setIsAuth(pathname !== '/' && (ROUTES.LOGIN.includes(pathname) || ROUTES.REGISTER.includes(pathname)))
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [pathname])

  return isAuth
}
