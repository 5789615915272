import cx from 'classnames'
import RichText from 'components/RichText'
import classes from './styles.module.scss'
import { useTranslation } from 'src/context/translationContext'
import ROUTES from 'temp/routes.json'
import { Link } from 'components/Link'

type Props = {
  isCompact?: boolean
  onClick?: () => void
}

export default function ReferFriendBlock(props: Props) {
  const t = useTranslation()

  return (
    <Link
      href={ROUTES.REFER_FRIEND}
      rel='noreferrer'
      className={cx(classes.root, { [classes.compact]: props.isCompact })}
      onClick={props.onClick}
    >
      <RichText
        field={t('common.refer_a_friend_banner_title')}
        components={{
          strong: ({ children }) => <span className={classes.textGreen}>{children}</span>,
          hyperlink: ({ children }) => <span className={classes.textMain}>{children}</span>,
        }}
      />
    </Link>
  )
}
