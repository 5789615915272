import classes from './styles.module.scss'
import { useEffect, useState } from 'react'
import cx from 'classnames'

import { IconButton } from '@patrianna/core-components'
import { useTranslation } from 'app/context/translations'
import { useAppSelector } from 'store/hooks'
import { getFavoriteGamesSelector } from 'store/modules/categoryGames'
import { getRecentlyPlayedGamesSelector, isCategoryGamesLoadedSelector } from 'store/modules/categoryGames/selectors'
import Loader from 'components/Loader'
import EmptyTile from 'components/BottomTabsBar/EmptyTile'
import SlotsRowContainer from 'components/BottomTabsBar/SlotsRowContainer'
import type { GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'
import useIsBottomTabsBar from 'hooks/useIsBottomTabsBar'
import { Close } from 'mdi-material-ui'
import { useIsMobile } from 'app/context/detectMobile'

const LAST_PLAYED = 'last_played' as const
const FAVOURITE = 'favourite' as const
const ICON_SIZE = 20

type TabKey = typeof LAST_PLAYED | typeof FAVOURITE

type Tabs = Record<
  TabKey,
  {
    title: string
    emptyTitle: string
    games: GameProductShort[]
    icon: string
  }
>

const tabs: Tabs = {
  [LAST_PLAYED]: {
    title: 'common.recently_played',
    games: [],
    icon: '/menu/access-time',
    emptyTitle: 'common.no_recently_items',
  },
  [FAVOURITE]: {
    title: 'common.favorites',
    games: [],
    icon: '/common/favorite',
    emptyTitle: 'common.no_favourites_items',
  },
}

export default function BottomTabsBar() {
  const isMobile = useIsMobile()
  const t = useTranslation()
  const [isOpened, setIsOpened] = useState(false)
  const [activeTab, setActiveTab] = useState<TabKey>(LAST_PLAYED)
  const isCategoryGamesLoaded = useAppSelector(isCategoryGamesLoadedSelector)
  const recentlyPlayedGames = useAppSelector(getRecentlyPlayedGamesSelector)
  const favouriteGames = useAppSelector(getFavoriteGamesSelector)
  const isBottomTabsBar = useIsBottomTabsBar()

  tabs[LAST_PLAYED].games = recentlyPlayedGames
  tabs[FAVOURITE].games = favouriteGames

  const games = tabs[activeTab].games

  useEffect(() => {
    if (!recentlyPlayedGames.length && favouriteGames.length) {
      setActiveTab(FAVOURITE)
    }
  }, [recentlyPlayedGames.length, favouriteGames.length])

  // update styles for chat icon and special offer icon
  useEffect(() => {
    if (isBottomTabsBar) {
      const style = document.createElement('style')
      style.innerHTML = `
        #chatbot-chat {
          bottom: 100px!important;
        }

         html.chatbot-mobile-opened #chatbot-chat {
          bottom: 0px!important;
        }
      `
      document.head.appendChild(style)
    }
  }, [isBottomTabsBar])

  useEffect(() => {
    const bottomBarStateEvent = new CustomEvent('BOTTOM_BAR_STATE', {
      detail: {
        isBottomOpened: isOpened,
      },
    })
    document.dispatchEvent(bottomBarStateEvent)
  }, [isOpened])

  const tabClickHandler = (tab: TabKey) => {
    if (tab !== activeTab || !isOpened) {
      setActiveTab(tab)
      setIsOpened(true)
    } else {
      setIsOpened(false)
    }
  }

  const handleClose = () => {
    setIsOpened(false)
  }

  if (!isBottomTabsBar) {
    return null
  }

  return (
    <section className={cx(classes.bottomTabsBar, !isMobile && classes.desktop)}>
      <div className={classes.accordionHeader}>
        {Object.entries(tabs).map(([tabKey, tabMeta]) => {
          return (
            <IconButton
              key={tabKey}
              className={cx(classes.button, activeTab === tabKey && isOpened && classes.active)}
              onClick={() => {
                tabClickHandler(tabKey as TabKey)
              }}
            >
              <img
                src={`${process.env.IMG_ORIGIN}${tabMeta.icon}.svg`}
                width={ICON_SIZE}
                height={ICON_SIZE}
                alt='icon'
              />
              <span>{t(tabMeta.title)}</span>
              <img
                className={isOpened && activeTab === tabKey ? classes.toggleDown : classes.toggleUp}
                src={`${process.env.IMG_ORIGIN}/menu/accordion_down.svg`}
                width={ICON_SIZE}
                height={ICON_SIZE}
                alt='accordion arrow down'
              />
            </IconButton>
          )
        })}
        {isOpened && !isMobile && (
          <IconButton className={classes.close} onClick={handleClose}>
            <Close />
          </IconButton>
        )}
      </div>
      <div className={cx(classes.accordionBody, { [classes.expanded]: isOpened })}>
        {!isCategoryGamesLoaded && activeTab === LAST_PLAYED && !recentlyPlayedGames.length ? (
          <Loader className={classes.loader} />
        ) : games?.length ? (
          <SlotsRowContainer key={activeTab} games={games} numberOfPreLoadedItems={isMobile ? 10 : 20} />
        ) : (
          <EmptyTile>{t(tabs[activeTab].emptyTitle)}</EmptyTile>
        )}
      </div>
    </section>
  )
}
