import { useEffect } from 'react'
import { useGraphyteSearchBase } from '@patrianna-payments/analytics/graphyte/hooks/useGraphyteSearchBase'
import type { UseSearchReturnType } from '@patrianna/shared-hooks/useSearch'
import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { getIsAdminUserSelector, getUserIdSelector } from 'store/modules/user/selectors'
import { getAllProducts } from 'store/modules/games/actions'
import { allVisibleGamesSelector } from 'store/modules/games/selectors'

interface UseGraphyteGameSearchReturnType extends UseSearchReturnType<GameProduct> {
  userId: string
  searchId: string
}

export const useGraphyteSearch = ({
  searchQuery,
  isFocused,
}: {
  isFocused: boolean
  searchQuery?: string
}): UseGraphyteGameSearchReturnType | null => {
  const isAdmin = useAppSelector(getIsAdminUserSelector)
  const userId = useAppSelector(getUserIdSelector)
  const games = useAppSelector(allVisibleGamesSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isFocused) {
      dispatch(getAllProducts())
    }
  }, [isFocused])

  return useGraphyteSearchBase({ games, isAdmin, userId, searchQuery, minCharsToSearch: 1 })
}
