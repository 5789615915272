import { Link } from 'components/Link'
import { useMemo } from 'react'
import { ArrowBottomRight } from 'mdi-material-ui'
import type { GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'
import classes from './styles.module.scss'
import GamesSectionItem from './GamesSectionItem'

type Props = {
  title: string
  games: GameProductShort[]
  clearSearch: () => void
  seeMorePath?: string
  limit?: number
  trackClick?: (code: string) => void
}

export default function GamesSection({ title, games, clearSearch, seeMorePath, limit, trackClick }: Props) {
  const isSeeMoreShown = seeMorePath && games.length > limit
  const visibleGames = useMemo(() => {
    const visibleGamesCount = isSeeMoreShown ? limit - 1 : limit

    return visibleGamesCount ? games?.slice(0, visibleGamesCount) : games
  }, [isSeeMoreShown, games, limit])

  return (
    <section className={classes.root}>
      <h6 className='mt-typography-subtitle1'>{title}</h6>
      <div className={classes.list}>
        {visibleGames?.map((game) => (
          <GamesSectionItem trackClick={trackClick} game={game} clearSearch={clearSearch} key={game.code} />
        ))}
        {isSeeMoreShown && (
          <div className={classes.seeMore}>
            <Link href={seeMorePath}>
              See more
              <ArrowBottomRight className={classes.icon} />
            </Link>
          </div>
        )}
      </div>
    </section>
  )
}
