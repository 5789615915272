import { useAuth } from 'app/context/auth'
import { usePathname } from 'next/navigation'
import ROUTES from 'temp/routes.json'
import { useAppSelector } from 'store/hooks'
import { getRecentlyPlayedGamesSelector } from 'store/modules/categoryGames/selectors'
import { getFavoriteGamesSelector } from 'store/modules/categoryGames'

const useIsBottomTabsBar = () => {
  const { isLoggedIn } = useAuth()
  const pathname = usePathname()
  const recentlyPlayedGames = useAppSelector(getRecentlyPlayedGamesSelector)
  const favouriteGames = useAppSelector(getFavoriteGamesSelector)
  const isHome = pathname === ROUTES.HOME

  return isLoggedIn && isHome && (favouriteGames.length || recentlyPlayedGames.length)
}

export default useIsBottomTabsBar
