import cx from 'classnames'
import { Information } from 'mdi-material-ui'
import { intlnumberFormat } from '@patrianna/shared-utils/numbers'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { openDialog } from 'store/modules/dialog/actions'
import { getSweepstakeCurrencySelector } from 'store/modules/currencies/selectors'
import classes from './styles.module.scss'
import { useTranslation } from 'context/translationContext'

type SweepstakeCurrencyProps = {
  hideMoreInfo?: boolean
  currencyClassName?: string
}

export default function SweepstakeCurrency({ hideMoreInfo, currencyClassName }: SweepstakeCurrencyProps) {
  const dispatch = useAppDispatch()
  const sweepstakeCurrency = useAppSelector(getSweepstakeCurrencySelector)
  const t = useTranslation()

  if (!sweepstakeCurrency) {
    return null
  }

  return (
    <div
      className={cx('mt-typography-caption', classes.currencyText, currencyClassName, {
        [classes.retreatForIcon]: !hideMoreInfo,
      })}
    >
      <img src={`${process.env.IMG_ORIGIN}/menu/money.png?v=3`} className={classes.icon} alt='sc icon' />
      <span>
        {t('common.sweepstakes_coins_sc')}{' '}
        {intlnumberFormat(sweepstakeCurrency.amount, 'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </span>

      {!hideMoreInfo && (
        <Information className={classes.info} onClick={() => dispatch(openDialog({ modalName: 'INFO_SC_DIALOG' }))} />
      )}
    </div>
  )
}
