import { ChevronLeft, ChevronRight } from 'mdi-material-ui'
import classes from './styles.module.scss'

type Props = {
  setHideMenu: Function
  hideMenu: boolean
}

export function ButtonHideMenu(props: Props) {
  const onCollapseMenu = () => props.setHideMenu(!props.hideMenu)

  return (
    <div onClick={onCollapseMenu} className={classes.button}>
      {props.hideMenu ? <ChevronRight /> : <ChevronLeft />}
    </div>
  )
}
