import { usePathname } from 'next/navigation'
import type { ReactNode } from 'react'

import { useAppSelector } from 'src/store/hooks'
import { isAppInitializedSelector } from 'store/modules/appConfig/selectors'
import MenuItem from 'containers/Menu/MenuItem'
import ROUTES from 'temp/routes.json'
import useGetGAEventMenuParams from 'containers/Menu/utilityHook'
import { trackEvent } from 'config/analytic'

type Props = {
  children?: ReactNode
  link?: string | string[]
  text?: string
  title?: string
  icon?: string
  onClick?: () => void
  key?: number
  gaEvent?: string
  gaEventOptions?: Record<string, string>
}

export default function MenuLink(props: Props) {
  const { link } = props
  const appInitialized = useAppSelector(isAppInitializedSelector)
  const { GAEventMenuParams } = useGetGAEventMenuParams()
  const pathname = usePathname()

  let isActive: boolean
  if (Array.isArray(link)) {
    isActive = link.includes(pathname) && appInitialized
  } else {
    isActive = link === pathname && appInitialized
  }
  const currentLink: string = link && Array.isArray(link) ? (link[0] as string) : (link as string)

  const handleClick = (): void => {
    if (props.gaEvent && !isActive) {
      trackEvent(props.gaEvent, {
        category: GAEventMenuParams.category,
        label: GAEventMenuParams.label,
        ...(props.gaEventOptions && props.gaEventOptions),
      })
    }

    props?.onClick()
  }

  if (currentLink) {
    return <MenuItem {...props} onClick={handleClick} isActive={isActive} href={currentLink || ROUTES.HOME} />
  }

  return <MenuItem {...props} onClick={handleClick} isActive={isActive} />
}
