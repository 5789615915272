import classes from './styles.module.scss'
import SlotsRow from 'pageContainers/Home/components/SlotsRow'
import type { GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'
import { useIsMobile } from 'app/context/detectMobile'

type Props = {
  games: GameProductShort[]
  numberOfPreLoadedItems: number
}
export default function SlotsRowContainer({ games, numberOfPreLoadedItems }: Props) {
  const isMobile = useIsMobile()

  return (
    <div className={classes.slotsRowContainer}>
      <SlotsRow
        showArrows={!isMobile}
        className={classes.slotsRow}
        count={games.length}
        numberOfPreLoadedItems={numberOfPreLoadedItems}
        games={games}
        title={'Games'}
        isMobileUserAgent={true}
        style={'small'}
        hideHeader={true}
      />
    </div>
  )
}
