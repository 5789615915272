import cx from 'classnames'
import { IconButton } from '@patrianna/core-components'
import Img from 'components/Img'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { redeemTrackGAEvent } from 'store/modules/redeem/actions'
import classes from './styles.module.scss'
import { openRedeemHistoryDialogAction } from 'store/modules/dialog/actions'
import { getRedeemHistoryEnabledSelector } from 'store/modules/user/selectors'
import type { ReactNode } from 'react'

type Props = {
  count: number
  counterClassName?: string
  icon: string
  label?: ReactNode
}

export function PendingRedeems({ count, counterClassName, icon, label }: Props) {
  const dispatch = useAppDispatch()
  const isRedeemHistoryEnabled = useAppSelector(getRedeemHistoryEnabledSelector)
  const onOpenRedeemHistoryDialog = () => {
    dispatch(openRedeemHistoryDialogAction())
    dispatch(redeemTrackGAEvent({ event: 'redeem_reversal_viewed', options: { page_path: 'home page' } }))
  }

  if (count > 0 && isRedeemHistoryEnabled) {
    return (
      <IconButton className={cx(classes.pendingRedeems)} onClick={onOpenRedeemHistoryDialog}>
        <div className={classes.pendingRedeems}>
          <div className={cx(classes.count, counterClassName)}>{count}</div>
          <Img src={icon} alt='Wallet icon' />
          {label}
        </div>
      </IconButton>
    )
  }

  return null
}
