import { compose } from '@reduxjs/toolkit'
import type { Currencies } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'
import type { DialogNames } from 'store/types'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { openDialog } from 'store/modules/dialog/actions'
import { openSnackbar } from 'store/modules/snackbar/actions'
import { getActiveCurrencyIdSelector } from 'store/modules/currencies/selectors'
import useShowChats from 'hooks/useShowChats'
import { useAuth } from 'app/context/auth'
import { useCountryConfig } from 'app/context/country'

type NestedLinkObject = {
  icon: string
  text: string
  id: number
  link: string
  flags?: Array<string>
  openDialog?: DialogNames
  openSnackbar?: string
  onClick?: () => void
  title?: string
  actionName?: string
  gaEvent?: string
}

export type LinkObject = NestedLinkObject & { links?: Array<NestedLinkObject> }

export type Links = Array<LinkObject>

type JsonLinks = Array<Omit<LinkObject, 'link'> & { link: string | { SC: string; GC: string } }>

const useBindEvents = (links: JsonLinks) => {
  const dispatch = useAppDispatch()
  const activeCurrency = useAppSelector(getActiveCurrencyIdSelector) as Exclude<Currencies, 'USD'>

  return links.map((el) => {
    if (Object.prototype.hasOwnProperty.call(el, 'openDialog')) {
      return {
        ...el,
        onClick: () => {
          dispatch(openDialog({ modalName: el.openDialog }))
        },
      }
    }

    if (Object.prototype.hasOwnProperty.call(el, 'openSnackbar')) {
      const message = el.openSnackbar

      return {
        ...el,
        onClick: () => {
          dispatch(openSnackbar({ message }))
        },
      }
    }

    if (Object.prototype.hasOwnProperty.call(el, 'actionName')) {
      return {
        ...el,
        onClick: () => {
          dispatch({ type: el.actionName })
        },
      }
    }

    if (typeof el?.link !== 'string' && el?.link?.SC) {
      return {
        ...el,
        link: el.link[activeCurrency],
      }
    }

    return el
  })
}

const useFilterLinksByFlags = (links: Links) => {
  const { isScEnabled: sweepstakeEnabled, isLoggedIn } = useAuth()
  const { loyaltyProgramFeature, helpCentreFeature, referFriendFeature } = useCountryConfig()
  const { isShowLiveChat } = useShowChats()

  const flags = {
    helpCentreFeature: helpCentreFeature.enabled,
    referFriendFeature: referFriendFeature.enabled,
    loyaltyProgramFeature: loyaltyProgramFeature.enabled,
    sweepstakeEnabled,
    isLoggedIn,
    isShowLiveChat,
  }

  return links
    .map((el) => {
      if (Object.prototype.hasOwnProperty.call(el, 'flags')) {
        return {
          ...el,
          show: el.flags.every((item) => flags[item as keyof typeof flags]),
        }
      }

      return {
        ...el,
        show: true,
      }
    })
    .filter((fl) => Boolean(fl.show))
}

export default compose<Links>(useBindEvents, useFilterLinksByFlags)
