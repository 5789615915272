import { useRouter } from 'app/context/navigation'
import { useCallback } from 'react'
import type { GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'
import ProductTile from 'components/ProductTile'
import { getSlotStartUrlByGameType } from 'utils/navigator/games'
import classes from './styles.module.scss'

type Props = {
  game: GameProductShort
  clearSearch: () => void
  trackClick?: (gameCode: string) => void
}

export default function GamesSectionItem(props: Props) {
  const router = useRouter()
  const url = `${getSlotStartUrlByGameType(props.game.type)}/${props.game.route}`

  const onClick = useCallback(() => {
    props.clearSearch()
    props.trackClick?.(props?.game?.code)
    router.push(url)
  }, [props, router, url])

  return <ProductTile game={props.game} className={classes.gamesSectionItem} handleTileClick={onClick} isSearch />
}
