import LogoLink from 'containers/LogoLink'
import MenuItems from 'containers/Menu/MenuItems'
import classes from './styles.module.scss'
import MenuFooter from '../MenuFooter'
import { usePathname } from 'next/navigation'
import ROUTES from 'temp/routes.json'
import useGetGAEventMenuParams from 'containers/Menu/utilityHook'
import { useAuth } from 'app/context/auth'

function MenuDesktop({ availableCategoriesLinks }: { availableCategoriesLinks: Record<string, string>[] }) {
  const { isLoggedIn } = useAuth()
  const pathname = usePathname()
  const { trackGAEventsInMenu } = useGetGAEventMenuParams()

  const onLogoClickHandler = () => {
    trackGAEventsInMenu(pathname, ROUTES.HOME, 'clicked_on_logo-to-home')
  }

  return (
    <>
      <div className={classes.logo}>
        <LogoLink handleOnClick={onLogoClickHandler} />
      </div>
      <MenuItems isLoggedIn={isLoggedIn} availableCategoriesLinks={availableCategoriesLinks} />
      <MenuFooter />
    </>
  )
}

export default MenuDesktop
