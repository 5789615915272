import { CardBulletedOutline, HomeOutline, Magnify, SlotMachineOutline } from 'mdi-material-ui'
import bottomNavigation from 'temp/bottomNavigation.json'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { openSnackbar } from 'store/modules/snackbar/actions'
import { getActiveCurrencyIdSelector } from 'store/modules/currencies/selectors'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import ShopMobileLink from 'src/containers/Menu/ShopMobileLink'

type LoggedPath = {
  loggedIn: string
  loggedOut: string
}

type CurrencyPath = {
  SC: string
  GC: string
  USD: string
}

type LinkItem = {
  path: LoggedPath | CurrencyPath | string
  icon: string
  label: string
  id: string
  openSnackbar?: string
  openDialog?: string
}

const Icons = {
  HomeOutline,
  SlotMachineOutline,
  Magnify,
  CardBulletedOutline,
  ShopMobileLink,
}

function isLoggedPath(path: CurrencyPath | LoggedPath | string): path is LoggedPath {
  return (path as LoggedPath)?.loggedIn !== undefined
}
function isCurrencyPah(path: CurrencyPath | LoggedPath | string): path is CurrencyPath {
  return (path as CurrencyPath)?.SC !== undefined
}

const useMobileBottomNavigation = () => {
  const loggedIn = useAppSelector(isLoggedInSelector)
  const activeCurrency = useAppSelector(getActiveCurrencyIdSelector)
  const dispatch = useAppDispatch()
  const linksArray = bottomNavigation as LinkItem[]

  return linksArray.map((item) => {
    const obj: any = {}
    if (isLoggedPath(item.path)) {
      obj.path = loggedIn ? item.path.loggedIn : item.path.loggedOut
    }

    if (isCurrencyPah(item.path)) {
      obj.path = item.path[activeCurrency]
    }

    if (item?.openSnackbar) {
      obj.onClick = () => {
        dispatch(
          openSnackbar({
            message: 'Coming soon!',
          })
        )
      }
    }

    const linkObj = {
      ...item,
      ...obj,
      icon: Icons[item.icon as keyof typeof Icons],
    }

    return {
      ...linkObj,
      path: linkObj?.path ? [linkObj?.path] : null,
    }
  })
}

export default useMobileBottomNavigation
