import { useMemo } from 'react'
import type { ReactNode } from 'react'
import classes from './styles.module.scss'

type Props = {
  children?: ReactNode[]
}

export default function SearchBarDropdown({ children }: Props) {
  const isNoChildren = useMemo(() => !children?.filter(node => node).length, [children])

  if (isNoChildren) {
    return null
  }

  return (
    <div className={classes.root}>
      {children}
    </div>
  )
}
