import type { ReactNode } from 'react'
import cx from 'classnames'
import classes from './styles.module.scss'

type Props = {
  condition: boolean
  children: ReactNode
  className?: string
}

export default function Skeleton({ condition, className, children }: Props) {
  if (!condition) {
    return <div className={cx(classes.skeletonBox, className)} />
  }

  return <>{children}</>
}
