import cx from 'classnames'
import classes from './styles.module.scss'
import { useAppSelector } from 'store/hooks'
import { getPendingRedeemsSelector } from '@patrianna-payments/shared-store/redeem/store/selectors'

export default function RedemptionsCount() {
  const pendingRedeems = useAppSelector(getPendingRedeemsSelector)

  if (!pendingRedeems?.count) {
    return null
  }

  return <span className={cx(classes.promoCount)}>{pendingRedeems?.count}</span>
}
