import type { ChangeEvent, FC, KeyboardEvent } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import { Backspace } from 'mdi-material-ui'
import { isIOS } from '@patrianna/shared-utils/helpers'
import classes from './styles.module.scss'
import { IconButton } from '../IconButton'

type Props = {
  onInputFocus?: () => void
  onInputBlur?: () => void
  onEnterPress?: () => void
  startAdornment?: boolean
  label?: string
  className?: string
  placeholder?: string
  autoFocus?: boolean
  value: string
  setValue: (value: string) => void
  EndAdornment?: FC<{ onClick: () => void }>
  helperText?: string
  containerClassName?: string
}

function SearchInput({
  onInputFocus,
  onInputBlur,
  onEnterPress,
  label,
  autoFocus,
  className,
  placeholder,
  value,
  setValue,
  EndAdornment,
  containerClassName,
}: Props) {
  const [hasFocus, setFocus] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!isIOS() && autoFocus) {
      // @ts-ignore
      inputRef.current?.focus()
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [])

  const handleInputFocus = async () => {
    setFocus(true)
    onInputFocus?.()
  }

  const handleInputBlur = () => {
    setFocus(false)
    onInputBlur?.()
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onEnterPress?.()
    }
  }

  const handleClose = useCallback(() => {
    setValue('')
  }, [setValue])

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value)
    },
    [setValue]
  )

  const AdornmentNode = useMemo(
    () =>
      EndAdornment ? (
        <EndAdornment onClick={handleClose} />
      ) : (
        <IconButton buttonStyle={classes.clearButton} onClick={handleClose}>
          <Backspace className={classes.backspaceIcon} />
        </IconButton>
      ),
    [EndAdornment, handleClose]
  )

  return (
    <div className={cx(classes.root, { [classes.focused]: hasFocus, [classes.active]: !!value }, containerClassName)}>
      <div className={cx(classes.adornment, classes.adornmentStart)}>
        <img className={classes.icon} src={`${process.env.IMG_ORIGIN}/menu/search_light_grey.svg`} alt='search icon' />
      </div>
      {!!label && <label className={classes.label}>{label}</label>}
      <input
        ref={inputRef}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        type='text'
        autoCorrect='off'
        autoCapitalize='none'
        name='search-input'
        className={cx(classes.input, className)}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      {!!value.length && <div className={cx(classes.adornment, classes.adornmentEnd)}>{AdornmentNode}</div>}
    </div>
  )
}

export default SearchInput
