import cx from 'classnames'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { logOut } from 'store/modules/auth/actions'
import { openDialog } from 'store/modules/dialog/actions'
import { redeemFlowStart } from 'store/modules/redeem/actions'
import { openShopHandler } from 'store/modules/shop/actions'
import { getKycStatusSelector, getUserAccountIdSelector } from 'store/modules/user/selectors'
import CashBalance from 'containers/CashBalance'
import GoldCurrency from 'containers/Layouts/Layout/Header/components/LoggedIn/GoldCurrency'
import SweepstakeCurrency from 'containers/Layouts/Layout/Header/components/LoggedIn/SweepstakeCurrency'
import { MenuAccordion } from 'containers/Menu/MenuItems/MenuAccordion'
import VIPProgressBar from 'containers/Menu/MenuItems/VIPProgressBar'
import MenuLink from 'containers/Menu/MenuLink'
import PWAInfo from 'containers/Menu/PWAInfo'
import ReferFriendBlock from 'containers/Menu/ReferFriendBlock'
import AdminProvider from 'src/containers/AdminProvider'
import Button from 'components/Button'
import PrismicDebugSwitcher from 'src/components/PrismicDebugSwitcher'
import classes from './styles.module.scss'
import ROUTES from 'temp/routes.json'
import secondaryMenuLinks from 'temp/secondaryMenuLinks.json'
import getLinks from '../utils'
import useGetGAEventMenuParams from 'containers/Menu/utilityHook'
import { useTranslation } from 'context/translationContext'
import { usePathname } from 'next/navigation'
import { useRouter } from 'app/context/navigation'
import { trackEvent } from 'config/analytic'
import { useAuth } from 'app/context/auth'
import GoldCoinGenerator from 'components/GoldCoinGeneratorSection'
import { useCountryConfig } from 'app/context/country'
import { trackOptimizelyEvent } from '@patrianna-payments/analytics/optimizely/events'
import usePaymentRedeemRestrictionsFunction from 'hooks/payments/usePaymentRedeemRestrictionsFunction'
import { useIsPulsate } from 'hooks/useIsPulsate'

const MY_PROFILE_URL = '/my-profile'

type MenuItemsProps = {
  toggleMenu?: (isOpen: boolean) => void
  className?: string
  isMobile?: boolean
  isLoggedIn?: boolean
  availableCategoriesLinks: Record<string, string>[]
}

const goldCoinGeneratorButtonProps = { dataTest: 'common-header-buy-button' }

function MenuItems({ className, toggleMenu, isMobile, isLoggedIn, availableCategoriesLinks }: MenuItemsProps) {
  const { push } = useRouter()
  const dispatch = useAppDispatch()
  const pathname = usePathname()
  const accountId = useAppSelector(getUserAccountIdSelector)
  const { GAEventMenuParams, trackGAEventsInMenu } = useGetGAEventMenuParams()
  const kycStatus = useAppSelector(getKycStatusSelector)
  const secondStep = ['id_confirmed', 'doc_declined'].includes(kycStatus)
  const { isScEnabled } = useAuth()
  const t = useTranslation()
  const { profilePageFeature, referFriendFeature, loyaltyProgramFeature } = useCountryConfig()

  const primaryLinks = getLinks(availableCategoriesLinks)
  const secondaryLinks = getLinks(secondaryMenuLinks)

  const isPulsate = useIsPulsate()

  const handleRedeemFlowStart = () => {
    trackEvent('clicked_on_redeem_menu', GAEventMenuParams)

    if (secondStep) {
      dispatch(openDialog({ modalName: 'SUBMIT_DOCUMENTS_DIALOG', dialogProps: { verificationFrom: 'redeem' } }))
    } else {
      dispatch(redeemFlowStart(push))
    }
  }

  const handleRedeemFlowStartWithRestrictions = usePaymentRedeemRestrictionsFunction(handleRedeemFlowStart)

  const onGetCoinsHandler = () => {
    trackEvent('clicked_on_buycoins_menu', GAEventMenuParams)
    trackOptimizelyEvent('clickedOnGetCoins', {
      account_id: accountId,
    })
    toggleMenu?.(false)
    dispatch(openShopHandler({ isMobile, routerPush: push }))
  }

  const onProfileHandler = () => {
    trackGAEventsInMenu(pathname, MY_PROFILE_URL, 'clicked_on_profile_menu')
    toggleMenu?.(false)
  }

  const onLogoutHandler = () => {
    trackGAEventsInMenu(pathname, MY_PROFILE_URL, 'clicked_on_logout_menu')
    toggleMenu?.(false)
    dispatch(logOut())
  }
  const handleClickReferFriend = (): void => {
    if (!pathname.includes(ROUTES.REFER_FRIEND)) {
      trackEvent('clicked_on_referfriend_menu_banner')
    }
    toggleMenu?.(false)
  }

  return (
    <div>
      {isLoggedIn && (
        <div className={cx(classes.balanceBlock, classes.links, className)}>
          <CashBalance
            dataAttribute='header'
            mode='headerMode'
            GoldCurrencyComponent={GoldCurrency}
            SweepstakeCurrencyComponent={SweepstakeCurrency}
            reversed
            currencyClassName={classes.currency}
          />

          <div className={classes.shopBtns}>
            <GoldCoinGenerator buttonProps={goldCoinGeneratorButtonProps}>
              <Button
                data-test='common-header-buy-button'
                onClick={onGetCoinsHandler}
                variant='contained'
                className={cx(classes.shopLink, isPulsate && !isMobile && classes.pulsate)}
              >
                {t('common.get_coins')}
              </Button>
            </GoldCoinGenerator>

            {isScEnabled && (
              <Button
                data-test='common-header-redeem-button'
                variant='outlined'
                className={cx(classes.redeemLink, classes.linkOutlined)}
                onClick={handleRedeemFlowStartWithRestrictions}
              >
                {t('common.redeem')}
              </Button>
            )}
          </div>
        </div>
      )}

      {isLoggedIn && loyaltyProgramFeature.enabled && <VIPProgressBar />}

      {referFriendFeature.enabled && isScEnabled && (
        <ReferFriendBlock isCompact={!isMobile} onClick={handleClickReferFriend} />
      )}

      <div className={cx(classes.links, className)}>
        {primaryLinks.map((i) =>
          i.links ? (
            <MenuAccordion key={i.id} linkObject={i} toggleMenu={toggleMenu} />
          ) : (
            <MenuLink
              {...i}
              key={i.id}
              onClick={() => {
                toggleMenu?.(false)
                i?.onClick?.()
              }}
            />
          )
        )}
      </div>

      <div className={cx(classes.links, className)}>
        <PWAInfo toggleMenu={toggleMenu} />

        {secondaryLinks.map((i) => {
          return (
            <MenuLink
              {...i}
              key={i.id}
              gaEvent={i.gaEvent}
              onClick={() => {
                toggleMenu?.(false)
                i?.onClick?.()
              }}
            />
          )
        })}
      </div>

      {isLoggedIn && (
        <div className={cx(classes.links, className)}>
          {profilePageFeature.enabled && (
            <MenuLink title='profile' onClick={onProfileHandler} icon='profile' link={MY_PROFILE_URL} />
          )}

          <MenuLink title='logout' onClick={onLogoutHandler} icon='logout' />

          <AdminProvider>
            <span className={cx(classes.text, classes.switcher)}>
              <PrismicDebugSwitcher />
            </span>
          </AdminProvider>
        </div>
      )}
    </div>
  )
}

export default MenuItems
