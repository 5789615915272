import type { SyntheticEvent } from 'react'
import { useEffect } from 'react'
import cx from 'classnames'
import { BottomNavigationAction, Drawer } from '@patrianna/core-components'
import { useMediaQuery, restoreScrollPos, saveScrollPos } from '@patrianna/shared-hooks'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { toggleMenu } from 'store/modules/dialog/actions'
import { getDialogVisibilityByNameSelector, getIsMenuOpenedSelector } from 'store/modules/dialog/selectors'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import LogoLink from 'containers/LogoLink'
import MenuItems from 'containers/Menu/MenuItems'
import { disableScrollForRoot, enableScrollForRoot } from 'utils/disableScroll'
import classes from './styles.module.scss'
import MenuFooter from '../../../MenuFooter'
import OpenDrawerIcon from './components/OpenDrawerIcon'
import { dismissUnreadPromotionsIcon } from 'store/modules/promotions'

function More({
  route,
  availableCategoriesLinks,
}: {
  route: string
  availableCategoriesLinks: Record<string, string>[]
}) {
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(isLoggedInSelector)
  const isOpen = useAppSelector(getIsMenuOpenedSelector)
  const isMobile = useMediaQuery('xs')
  const isExtraGoldDialogVisible = useAppSelector((state) =>
    getDialogVisibilityByNameSelector(state, 'EXTRA_GOLD_DIALOG')
  )
  const isShopDialogVisible = useAppSelector((state) => getDialogVisibilityByNameSelector(state, 'SHOP_DIALOG'))

  const toggleMenuAction = (open: boolean) => {
    dispatch(toggleMenu(open))

    if (open) {
      setTimeout(() => {
        saveScrollPos(route)
        disableScrollForRoot()
      }, 200)

      dispatch(dismissUnreadPromotionsIcon())
    } else {
      enableScrollForRoot()
      restoreScrollPos(route)
    }
  }

  const toggleDrawer = (open: boolean) => (event: SyntheticEvent<any, Event> | KeyboardEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    toggleMenuAction(open)
  }

  useEffect(() => {
    if ((isExtraGoldDialogVisible || (isMobile && isShopDialogVisible)) && isOpen) {
      toggleMenuAction(false)
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [isMobile, isExtraGoldDialogVisible, isShopDialogVisible, isOpen])

  useEffect(() => {
    if (!isOpen) {
      enableScrollForRoot()
    }
  }, [isOpen])

  return (
    <>
      {!isOpen ? (
        <BottomNavigationAction
          icon={
            <OpenDrawerIcon>
              <img className={classes.closeImage} src={`${process.env.IMG_ORIGIN}/menu/menu.png`} alt='Menu' />
            </OpenDrawerIcon>
          }
          onClick={toggleDrawer(true)}
          className={classes.navigationButton}
        />
      ) : (
        <BottomNavigationAction
          icon={<img className={classes.closeImage} src={`${process.env.IMG_ORIGIN}/menu/close.png`} alt='Close' />}
          onClick={toggleDrawer(false)}
          className={cx(classes.navigationButton, classes.moreMenuClose)}
        />
      )}

      <Drawer
        className={classes.root}
        open={isOpen}
        onClose={toggleDrawer(false)}
        modalClassName={classes.drawerModal}
        disableEnforceFocus
        disableBackdrop
      >
        <div className={cx(classes.drawer, classes.drawerBlurred)}>
          <div className={classes.topContent}>
            <div className={classes.logoLink}>
              <LogoLink handleOnClick={() => toggleMenuAction(false)} />
            </div>
          </div>
          <MenuItems
            toggleMenu={toggleMenuAction}
            isMobile
            isLoggedIn={isLoggedIn}
            availableCategoriesLinks={availableCategoriesLinks}
          />
          <MenuFooter />
        </div>
      </Drawer>
    </>
  )
}

export default More
