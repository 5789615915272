import { usePathname } from 'next/navigation'
import { useMediaQuery } from '@patrianna/shared-hooks'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { openShopHandler } from 'store/modules/shop/actions'
import { getSpecialOfferAmount } from 'store/modules/shop/selectors'
import { showSpecialOffer } from 'store/modules/user/selectors'
import Img from 'components/Img'
import classes from './styles.module.scss'
import ROUTES from 'temp/routes.json'
import { getTodayBonusSelector } from 'src/store/modules/bonusesFlow/selectors'
import { useRouter } from 'app/context/navigation'
import { useState } from 'react'
import useDomEvent from 'hooks/useDomEvent'
import cx from 'classnames'

const BONUSES_AMOUNT = 1
export default function SpecialOfferButton() {
  const { push } = useRouter()
  const pathname = usePathname()
  const dispatch = useAppDispatch()
  const specialOfferAmount = useAppSelector(getSpecialOfferAmount)
  const isShowSpecialOffer = useAppSelector(showSpecialOffer)
  const isDailyRewardAvailable = useAppSelector(getTodayBonusSelector)
  // check available bonuses if yes +1 to special offers amount
  const amount = isDailyRewardAvailable ? specialOfferAmount + BONUSES_AMOUNT : specialOfferAmount

  const isMobile = useMediaQuery('xs')

  const isHome = pathname === ROUTES.HOME
  const isShowOnRoutes = pathname === ROUTES.LANDING_PAGE || isHome

  // if BottomTabsBar expanded slides component up
  const [isSlideUp, setIsSlideUp] = useState(false)
  const bottomBarStateHandler = (e: CustomEvent) => {
    setIsSlideUp(e.detail.isBottomOpened)
  }
  useDomEvent('BOTTOM_BAR_STATE', bottomBarStateHandler)

  if (isShowSpecialOffer && isShowOnRoutes) {
    return (
      <div className={cx(classes.root, isSlideUp && !isMobile && classes.slidedUp)}>
        <span className={classes.amount}>{amount}</span>
        <button
          type='button'
          onClick={() => {
            dispatch(
              openShopHandler({
                isMobile,
                cta: 'gift_icon',
                location: isHome ? 'homepage' : 'other',
                routerPush: push,
              })
            )
          }}
          className={classes.button}
        >
          <Img className={classes.present} src={`${process.env.IMG_ORIGIN}/icons/present-icon.png`} />
        </button>
      </div>
    )
  }

  return null
}
