import cx from 'classnames'
import classes from './styles.module.scss'
import { useAppSelector } from 'store/hooks'
import { getUnreadPromotionsAmount } from 'store/modules/promotions'

type Props = {
  className?: string
}
export default function PromotionsCount({ className }: Props) {
  const unreadPromotionsCount = useAppSelector(getUnreadPromotionsAmount)

  if (!unreadPromotionsCount) {
    return null
  }

  return <span className={cx(classes.promoCount, className)}>{unreadPromotionsCount}</span>
}
