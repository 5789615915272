import type { PropsWithChildren } from 'react'
import classes from './styles.module.scss'
import { useAppSelector } from 'store/hooks'
import { getUnreadPromotionsAmount, isUnvisitedPromotionsIconDismissed } from 'store/modules/promotions'

export default function OpenDrawerIcon({ children }: PropsWithChildren) {
  const unvisitedPromotionsIconDismissed = useAppSelector(isUnvisitedPromotionsIconDismissed)
  const unreadPromotionsAmount = useAppSelector(getUnreadPromotionsAmount)

  const showUnreadPromotionsBadge = !!unreadPromotionsAmount && !unvisitedPromotionsIconDismissed

  return (
    <div className={classes.openDrawerIconWrapper}>
      {showUnreadPromotionsBadge && <div className={classes.openDrawerBadge} />}
      {children}
    </div>
  )
}
