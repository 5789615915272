import cx from 'classnames'
import { useTranslation } from 'context/translationContext'
import classes from './styles.module.scss'

type Props = {
  className?: string
}

export default function NoResults({ className }: Props) {
  const t = useTranslation()

  return (
    <section className={cx(classes.root, className)}>
      <h6>{t('common.results')}</h6>
      <p className={cx(classes.description, classes.withShadow)}>{t('common.search_no_matches')}</p>
    </section>
  )
}
