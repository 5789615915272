import cx from 'classnames'
import { useTranslation } from 'context/translationContext'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import useIsAuthPage from 'hooks/useIsAuthPage'
import ButtonLink from 'components/ButtonLink'
import classes from './styles.module.scss'
import ROUTES from 'temp/routes.json'
import { toggleMenu } from 'store/modules/dialog/actions'
import { getIsMenuOpenedSelector } from 'store/modules/dialog/selectors'

type LoggedOutProps = {
  className?: string
}

function LoggedOut({ className }: LoggedOutProps) {
  const dispatch = useAppDispatch()
  const t = useTranslation()
  const isAuthPage = useIsAuthPage()
  const isOpen = useAppSelector(getIsMenuOpenedSelector)
  const handleMenuClose = () => {
    if (isOpen) {
      dispatch(toggleMenu(false))
    }
  }

  return (
    !isAuthPage && (
      <div className={cx(classes.buttonsContainer, className)}>
        <ButtonLink
          href={ROUTES.LOGIN}
          className={cx(classes.loginLink, classes.linkOutlined)}
          data-test='header-login-btn'
          variant='outlined'
          onClick={handleMenuClose}
        >
          {t('common.login')}
        </ButtonLink>

        <ButtonLink
          href={ROUTES.REGISTER}
          className={classes.signupLink}
          data-test='header-register-btn'
          onClick={handleMenuClose}
        >
          {t('common.join_now')}
        </ButtonLink>
      </div>
    )
  )
}

export default LoggedOut
