import ROUTES from 'temp/routes.json'

export const getLocationForEventsByPathname = (pathname: string): 'homepage' | 'ingame' | 'other' => {
  if (pathname === ROUTES.HOME) {
    return 'homepage'
  }

  if (pathname?.includes('/play') && !pathname?.includes('/play-')) {
    return 'ingame'
  }

  return 'other'
}
