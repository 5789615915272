'use client'

import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import cx from 'classnames'
import { useScrollRestoration } from '@patrianna/shared-hooks'
import GlobalSnackbar from 'containers/GlobalSnackbar'
import { ButtonHideMenu } from 'containers/Layouts/Layout/ButtonHideMenu'
import MenuDesktop from 'containers/Menu/MenuDesktop'
import MenuMobile from 'containers/Menu/MenuMobile'
import SmoothscrollLoader from 'containers/SmoothscrollLoader'
import Hidden from 'components/Hidden'
import MaintenanceMessage from 'components/MaintenanceMessage'
import classes from './Layout.module.scss'
import Footer from './Footer'
import Header from './Header'
import FavoriteButton from 'containers/FavoriteButton'
import type { GameProduct, GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'
import useIsBottomTabsBar from 'hooks/useIsBottomTabsBar'
import BottomTabsBar from 'components/BottomTabsBar'
import { useIsMobile } from 'app/context/detectMobile'

type Props = {
  children: ReactNode
  collapseMenu?: boolean
  isHeaderWithLogo?: boolean
  isHeaderWithClose?: boolean
  hideFooter?: boolean
  gameProduct?: GameProduct | GameProductShort
  availableCategoriesLinks: Record<string, string>[]
}

export default function Layout({
  collapseMenu,
  isHeaderWithLogo,
  isHeaderWithClose,
  hideFooter,
  children,
  gameProduct,
  availableCategoriesLinks,
}: Props) {
  const [hideMenu, setHideMenu] = useState<undefined | boolean>()

  useScrollRestoration()

  useEffect(() => {
    setHideMenu(collapseMenu)
  }, [collapseMenu])

  const isBottomTabsBar = useIsBottomTabsBar()
  const isMobile = useIsMobile()

  return (
    <>
      <Header
        favoriteButton={<FavoriteButton gameProduct={gameProduct} />}
        isHeaderWithLogo={isHeaderWithLogo}
        fullWidth={collapseMenu}
        isHeaderWithClose={isHeaderWithClose}
      />
      <SmoothscrollLoader />

      <div className={cx(classes.menuDesktop, { [classes.menuDesktopCollapse]: hideMenu })}>
        <Hidden visibleFor='desktop'>
          <MenuDesktop availableCategoriesLinks={availableCategoriesLinks} />
          {collapseMenu && <ButtonHideMenu setHideMenu={setHideMenu} hideMenu={hideMenu} />}
        </Hidden>
      </div>

      <MenuMobile availableCategoriesLinks={availableCategoriesLinks} />

      <main className={cx(classes.main, { [classes.fullContent]: hideMenu })}>
        {process.env.MAINTENANCE && <MaintenanceMessage />}
        <div className={`${isHeaderWithLogo ? '' : 'main-container limited'}`}>{children}</div>
      </main>
      {!isMobile && <BottomTabsBar />}
      {!hideFooter && (
        <div className={cx(classes.footer, isBottomTabsBar && classes.extraPadding)}>
          <Footer isFooterCentered={collapseMenu} className={`${isHeaderWithLogo ? '' : 'main-container limited'}`} />
        </div>
      )}

      <GlobalSnackbar />
    </>
  )
}
