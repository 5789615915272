import { usePathname } from 'next/navigation'
import { useEffect, useMemo, useState } from 'react'
import type { ReactNode } from 'react'
import cx from 'classnames'
import { AppBar } from '@patrianna/core-components'
import { getQuickPurchaseSupportedTypeSelector } from '@patrianna-payments/shared-store/payments/store/selectors'
import { useTranslation } from 'context/translationContext'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { openShopHandler } from 'store/modules/shop/actions'
import SearchBar from 'containers/Layouts/Layout/Header/components/SearchBar'
import LogoWithBackButton from 'containers/Layouts/components/LogoWithBackButton'
import LogoLink from 'containers/LogoLink'
import Button from 'components/Button'
import CloseButton from 'components/CloseButton'
import SpecialOfferButton from 'components/SpecialOfferButton'
import { isSearchPage, isShopPage } from 'utils/route'
import classes from './styles.module.scss'
// import StickyBar from 'components/StickyBar'
import LoggedOut from './components/LoggedOut'
import { useAuth } from 'app/context/auth'
import GoldCoinGenerator from 'components/GoldCoinGeneratorSection'
import { useMediaQuery } from '@patrianna/shared-hooks'
import { useIsMobile } from 'app/context/detectMobile'
import { useRouter } from 'app/context/navigation'
import { getLocationForEventsByPathname } from 'utils/analytics'

type Props = {
  isHeaderWithLogo?: boolean
  isHeaderWithClose?: boolean
  fullWidth?: boolean
  favoriteButton?: ReactNode
}

const goldCoinGeneratorButtonProps = {
  className: classes.getCoin,
  dataTest: 'header-store-btn',
}

export default function LayoutHeader({ isHeaderWithLogo, isHeaderWithClose, fullWidth, favoriteButton }: Props) {
  const { push } = useRouter()
  const pathname = usePathname()
  const dispatch = useAppDispatch()
  const { isQuickPurchase } = useAppSelector((state) => getQuickPurchaseSupportedTypeSelector(state, pathname))
  const [isScrolled, setIsScrolled] = useState(false)

  const isMobileAgent = useIsMobile()
  const isMobileMedia = useMediaQuery('xs')
  const isMobile = isMobileAgent || isMobileMedia
  const { isLoggedIn } = useAuth()
  const t = useTranslation()
  const locationForEvent = useMemo(() => getLocationForEventsByPathname(pathname), [pathname])

  const showGetCoins = isLoggedIn && !isShopPage(pathname)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10 && !isScrolled)
    }

    document.addEventListener('scroll', handleScroll)

    return () => document.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [])

  return (
    <>
      {/* <StickyBar /> */}
      <AppBar
        className={cx(classes.root, {
          [classes.solidAppbar]: isScrolled,
          [classes.isHeaderWithLogo]: isHeaderWithLogo,
        })}
        fixed
      >
        <div className={cx('main-container', classes.headerWrapper, fullWidth ? '' : 'limited')}>
          {isMobile ? (
            <div className={cx({ [classes.logoWithClose]: isHeaderWithClose })}>
              <LogoLink className={classes.logo} />
              {isHeaderWithClose && <CloseButton />}
            </div>
          ) : (
            <>
              {isHeaderWithLogo && <LogoWithBackButton />}
              {favoriteButton}
              {!isSearchPage(pathname) && <SearchBar />}
              {!isLoggedIn && <LoggedOut className={classes.buttons} />}
              {showGetCoins && fullWidth && (
                <div className={classes.buttons}>
                  <GoldCoinGenerator buttonProps={goldCoinGeneratorButtonProps}>
                    <Button
                      onClick={() =>
                        dispatch(
                          openShopHandler({
                            isMobile: false,
                            location: locationForEvent,
                            routerPush: push,
                            isQuickPurchaseFlow: true,
                            trackQuickPurchaseEvent: true,
                          })
                        )
                      }
                      className={cx(classes.getCoin, classes.pulsate)}
                      data-test='header-store-btn'
                    >
                      {isQuickPurchase ? t('common.quick_purchase') : t('common.get_coins')}
                    </Button>
                  </GoldCoinGenerator>
                </div>
              )}
            </>
          )}
        </div>
      </AppBar>

      <SpecialOfferButton />
    </>
  )
}
