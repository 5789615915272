import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getUserSelector } from 'store/modules/user/selectors'
import { closeAllDialogs, openDialog } from 'store/modules/dialog/actions'
import { useCallback } from 'react'

const REQUIRES_JUMIO_KYC_STATUS = 'REQUIRES_JUMIO_KYC_STATUS'

const usePaymentRedeemRestrictionsFunction = <T extends (...args: unknown[]) => unknown>(cb: T): T => {
  const user = useAppSelector(getUserSelector)
  const dispatch = useAppDispatch()

  return useCallback(
    (...args: Parameters<T>) => {
      if (user?.status === REQUIRES_JUMIO_KYC_STATUS) {
        dispatch(closeAllDialogs())
        dispatch(openDialog({ modalName: 'GET_VERIFIED_DIALOG' }))

        return null
      }

      return cb(...args) as ReturnType<T>
    },
    [cb, user]
  ) as unknown as T
}

export default usePaymentRedeemRestrictionsFunction
