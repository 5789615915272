import { useEffect, useState } from 'react'
import type { LocalStorageKeys } from 'src/utils/localStorage'
import { getItemFormLocalStorageSelector } from 'src/utils/localStorage'
import { useAppSelector } from 'src/store/hooks'

export default function useGetDataFromLocalStorage(key: LocalStorageKeys, personalized?: boolean) {
  const [localState, setState] = useState(null)
  const data = useAppSelector((state) => getItemFormLocalStorageSelector(state, key, personalized))

  useEffect(() => {
    setState(data)
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [])

  return localState
}
