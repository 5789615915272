import type { MouseEvent } from 'react'
import { useMemo } from 'react'
import { usePathname } from 'next/navigation'
import cx from 'classnames'
import { useTranslation } from 'context/translationContext'
import { BottomNavigation, BottomNavigationAction } from '@patrianna/core-components'
import { getPendingRedeemsSelector } from '@patrianna-payments/shared-store/redeem/store/selectors'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { toggleMenu } from 'store/modules/dialog/actions'
import { openShopHandler } from 'store/modules/shop/actions'
import { getIsMenuOpenedSelector } from 'store/modules/dialog/selectors'
import useIsAuthPage from 'hooks/useIsAuthPage'
import CashBalance from 'containers/CashBalance'
import GoldCurrency from 'containers/Layouts/Layout/Header/components/LoggedIn/GoldCurrency'
import SweepstakeCurrency from 'containers/Layouts/Layout/Header/components/LoggedIn/SweepstakeCurrency'
import LoggedOut from 'containers/Layouts/Layout/Header/components/LoggedOut'
import { PendingRedeems } from 'src/containers/Menu/MenuMobile/components/PendingRedeems'
import Button from 'components/Button'
import { isSearchPage, isShopPage } from 'utils/route'
import classes from './styles.module.scss'
import mobileBottomNavigation from 'config/mobileBottomNavigation'
import ROUTES from 'temp/routes.json'
import More from 'src/containers/Menu/MenuMobile/components/More'
import { useAuth } from 'app/context/auth'
import GoldCoinGenerator from 'components/GoldCoinGeneratorSection'
import { useRouter } from 'app/context/navigation'
import BottomTabsBar from 'src/components/BottomTabsBar'
import { getLocationForEventsByPathname } from 'utils/analytics'
import { useIsMobile } from 'app/context/detectMobile'
import { useIsPulsate } from 'hooks/useIsPulsate'

const goldCoinGeneratorButtonProps = {
  dataTest: 'common-header-buy-button',
  className: classes.shopLink,
}

export default function MenuMobile({
  availableCategoriesLinks,
}: {
  availableCategoriesLinks: Record<string, string>[]
}) {
  const { push } = useRouter()
  const pathname = usePathname()
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(getIsMenuOpenedSelector)
  const { isLoggedIn } = useAuth()
  const pendingRedeems = useAppSelector(getPendingRedeemsSelector)
  const t = useTranslation()

  const mobileMenuLinks = mobileBottomNavigation()

  const isAuthPage = useIsAuthPage()
  const locationForEvent = useMemo(() => getLocationForEventsByPathname(pathname), [pathname])
  const isMobile = useIsMobile()
  const isPulsate = useIsPulsate()

  const handleGetCoins = () => {
    dispatch(openShopHandler({ isMobile: true, location: locationForEvent, routerPush: push }))

    if (isOpen) {
      dispatch(toggleMenu(false))
    }
  }

  const handleSearch = () => {
    push(ROUTES.SEARCH)

    if (isOpen) {
      dispatch(toggleMenu(false))
    }
  }

  const onChangeHandler = (e: MouseEvent, newValue: number) => {
    // get the first link route array
    const onClick = mobileMenuLinks[newValue]?.onClick

    if (onClick) {
      onClick()
    }
  }

  return (
    <div className={cx(classes.root, 'mt-hidden-desktop')} color='default'>
      {isMobile && <BottomTabsBar />}
      {!isAuthPage && (
        <BottomNavigation onChange={onChangeHandler} className={classes.bottomNavigation}>
          {isLoggedIn ? (
            <div className={classes.cashShop}>
              <div className={classes.cash}>
                <CashBalance
                  hideMoreInfo
                  dataAttribute='header'
                  mode='headerMode'
                  GoldCurrencyComponent={GoldCurrency}
                  SweepstakeCurrencyComponent={SweepstakeCurrency}
                />
              </div>
              {!isShopPage(pathname) && (
                <GoldCoinGenerator buttonProps={goldCoinGeneratorButtonProps}>
                  <Button
                    data-test='common-header-buy-button'
                    onClick={handleGetCoins}
                    variant='contained'
                    className={cx(classes.shopLink, isPulsate && classes.pulsate)}
                  >
                    {t('common.get_coins')}
                  </Button>
                </GoldCoinGenerator>
              )}
            </div>
          ) : (
            <LoggedOut />
          )}
          <div className={classes.menuActions}>
            <PendingRedeems icon={`${process.env.IMG_ORIGIN}/icons/wallet-white.svg`} count={pendingRedeems?.count} />
            {isSearchPage(pathname) ? (
              <BottomNavigationAction
                icon={<img className={classes.image} src={`${process.env.IMG_ORIGIN}/menu/close.png`} alt='Close' />}
                onClick={() => push(ROUTES.HOME)}
                className={cx(classes.navigationButton)}
              />
            ) : (
              <BottomNavigationAction
                icon={<img className={classes.image} src={`${process.env.IMG_ORIGIN}/menu/search.png`} alt='Search' />}
                className={cx(classes.navigationButton)}
                onClick={handleSearch}
              />
            )}
            <More route={pathname} availableCategoriesLinks={availableCategoriesLinks} />
          </div>
        </BottomNavigation>
      )}
    </div>
  )
}
