import type { ReactNode } from 'react'
import cx from 'classnames'
import classes from './styles.module.scss'

type Props = {
  children?: ReactNode
  disabled?: boolean
  className?: string
  buttonStyle?: string
  iconStyle?: string
  labelStyle?: string
  icon?: string
  label?: string
  onClick: () => void
}

export function IconButton({ icon, label, onClick, buttonStyle, iconStyle, labelStyle, disabled, children }: Props) {
  return (
    <button onClick={onClick} disabled={disabled} className={cx(classes.button, buttonStyle)} type='button'>
      {icon ? <img src={icon} className={iconStyle} alt={iconStyle} /> : null}
      {label ? <span className={cx(classes.label, labelStyle)}>{label}</span> : null}
      {children}
    </button>
  )
}
